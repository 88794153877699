import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { Container } from "../../components/container"
import SEO from "../../components/seo"
import styles from "./cookies.module.css"

const CookiesPageEn = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title="Cookies"
      />
      <Container className={styles.cookies}>
        <h1 className="text-center">
          Cookies
        </h1>

        <section>
            <p>
              In this document we would like to inform you, in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC DSGVO about the processing of personal data, that occurs when visiting the website of our company Nutritionpro GmbH, registered address at Dingolfinger Straße 15, 81673 München (the “controller”) via cookies.
            </p>
            <p>
              The driverama.de website uses cookies to ensure the best possible performance of our website. These files can collect information about your behavior on our website, about your device and your preferences. The information that we process is not used to identify your person, but to provide top-quality user interface and better targeted advertisements. Disabling certain types of cookies may restrict your work with the website and the services we offer you. If you disable all cookies, you will always be asked to set your preferences for the website again every time you visit the website. When using a new device or browser, you also need to set your preferences again.
            </p>
            <details>
              <summary>
                <h3>WE PROCESS THESE TYPES OF COOKIES:</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Essential cookies:<br/>
                These cookies are essential for the operation of our website. They are always used and cannot be disabled. At the same time, these cookies do not store any personal information that could be used to identify you. They function as a reaction to your actions on our website, such as logging into your profile, filling in questionnaires, etc.
                The legal foundation for processing of these cookies are our justified interests.
              </p>
              <table>
                <thead>
                  <tr><th>COOKIE NAME</th><th>DESCRIPTION</th><th>STORAGE PERIOD</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>auth.csrf</td>
                    <td>CSRF is also used as an abbreviation in defences against CSRF attacks, such as techniques that use header data, form data, or cookies, to test for and prevent such attacks.	</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>auth.session</td>
                    <td>A session cookie (also known as an in-memory cookie, transient cookie or non-persistent cookie) exists only in temporary memory while the user navigates a website. Session cookies expire or are deleted when the user closes the web browser.	</td>
                    <td>30 days</td>
                  </tr>
                  <tr>
                    <td>auth.expiry</td>
                    <td>The cookie is used for authentication and expiration of the authentication.	</td>
                    <td>30 days</td>
                  </tr>
                  <tr>
                    <td>__next_preview_data</td>
                    <td>This cookie allows a user to view draft content immediately on the same URL as your statically generated routes.	</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>NEXT_LOCALE</td>
                    <td>Next.js supports overriding the accept-language header with a NEXT_LOCALE=the-locale cookie. This cookie can be set using a language switcher and then when a user comes back to the site it will leverage the locale specified in the cookie.	</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>Facebook</td>
                    <td>Cookies used by third parties to log in to the user's account if the user uses their Facebook.com profile to log in. These are third party cookies. Information about the cookies used is available here: <a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a> According to the 3rd party settings. Unlike cookies that are set on Facebook's own domains, these cookies aren't accessible by Facebook when you're on a site other than the one on which they were set, including when you are on one of our domains. They serve the same purposes as cookies set in Facebook's own domain, which are to personalise content (including ads), measure ads, produce analytics and provide a safer experience, as set out in this Cookies Policy. N/A - all information here <a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a></td>
                    <td>According to the third party configuration</td>
                  </tr>
                  <tr>
                    <td>Google</td>
                    <td>Third-party cookies for logging into the user account if the user uses their Google profile for logging in. <strong>This is a third-party cookie</strong>. Information about these cookies is available here: <a href="https://policies.google.com/technologies/cookies#types-of-cookies">https://policies.google.com/technologies/cookies#types-of-cookies</a></td>
                    <td>According to the third party configuration</td>
                  </tr>
                </tbody>
              </table>
              <p>Performance cookies: <br/>
                This type of cookies helps us improve the performance of our website, as it counts the number of visits and the sources of traffic. They help determine the visit rate, and therefore the popularity of individual parts of the website. All data collected within these cookies are completely anonymous. You can disable these cookies on your website and if you do so, we will not see when you visited the web or which parts of the website you looked at.
                The legal foundation for
              </p>

              <table>
                <thead>
                  <tr><th>COOKIE NAME</th><th>DESCRIPTION</th><th>STORAGE PERIOD</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hotjar Tracking</td>
                    <td><strong>This is a third-party cookie.</strong> Hotjar cookies are responsible for displaying the correct content to your users without personally identifying anyone. <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script</a></td>
                    <td>Up to 1 year</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _ga</td>
                    <td>For user tracking.</td>
                    <td>2 days</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _gid</td>
                    <td>This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing.	</td>
                    <td>24 hours</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _ga_&lt;\container-id&gt;</td>
                    <td>is used to identify unique users.	</td>
                    <td>2 years</td>
                  </tr>
                </tbody>
              </table>

              <p>
                Marketing cookies: <br/>
                These cookies are used to create the best possible profile of your interests and subsequently for displaying of ads based on your preferences. These cookies may be shared with personal data recipients in order to create a profile of your interests so as to display relevant advertisements on other websites. These use unique identification of your device and your internet browser. If you disable this type of cookies, the ads you will see will not be as targeted and may not correspond to your interests.
                The legal foundation for processing of these cookies is your consent that you are entitled to withdraw at any time.
              </p>

              <table>
                <thead>
                  <tr><th>COOKIE NAME</th><th>DESCRIPTION</th><th>STORAGE PERIOD</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>_gads</td>
                    <td>This cookie enables sites to show Google ads, including personalized ads. GOOGLE – 3RD PARTY COOKIE</td>
                    <td>13 months</td>
                  </tr>
                  <tr>
                    <td><em>gac</em></td>
                    <td>Cookies that start with ‘gac’ come from Google Analytics and are used by advertisers to measure user activity and the performance of their ad campaigns. GOOGLE – 3RD PARTY COOKIE</td>
                    <td>90 days</td>
                  </tr>
                  <tr>
                    <td><em>glc</em></td>
                    <td>cookies that start with ‘gcl’ come from Google Analytics and are primarily used to help advertisers determine how many times users who click on their ads end up taking an action on their site, such as making a purchase. GOOGLE – 3RD PARTY COOKIE	</td>
                    <td>90 days</td>
                  </tr>
                  <tr>
                    <td>ANID</td>
                    <td>	‘ANID’ is used to remember this setting</td>
                    <td>13 months</td>
                  </tr>
                  <tr>
                    <td>NID</td>
                    <td>‘NID’ is used to store setting	</td>
                    <td>6 months</td>
                  </tr>
                  <tr>
                    <td>IDE</td>
                    <td>‘IDE’ is used to store setting</td>
                    <td>13 months</td>
                  </tr>
                  <tr>
                    <td>DSID</td>
                    <td>‘DSID’ is used to identify a signed-in user on non-Google sites and to remember whether the user has agreed to ad personalization GOOGLE – 3RD PARTY COOKIE	</td>
                    <td>2 weeks</td>
                  </tr>
                </tbody>
              </table>
            </details>

            <details>
              <summary>
                <h3>Rights of data subjects</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                All information about your rights in relation to processing of your personal data including more detailed information about the controller and the person responsible for personal data protection is available at the following website: <a href="/terms" title="Privacy policy">Privacy policy</a>
              </p>
            </details>

            <details>
              <summary>
                <h3>Automated decision-making and profiling</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Automatic processing of personal data and profiling may occur within processing of personal data; this happens when you consent with processing of marketing cookies used also for displaying special offers based on information about your person.
              </p>
            </details>

            <details>
              <summary>
                <h3>Purpose and legal foundation for processing</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                We process personal data provided to us during your visit of our website in order to customize the content of the website when you visit our website again and the third-party ads displayed on our website.
                The legal foundation differs for different types of cookies, as described below.
                In order to improve the quality of our provided services, we use permanent and session cookies that are active only for the duration of your stay on the website.
                We use both first-party cookies and third-party cookies.
                First-party cookies are cookies configured directly by us.
                Third-party cookies are cookies configured by a third party different from us. Such cookies originate from various third party that flexibly use these cookies to monitor the behavior of user on websites outside of the third party’s offer (namely in relation to targeted advertisements). Data gathered within these cookies are processed by a third party under its own responsibility or together with use.
                When processing cookies, we cooperate with the following third parties as separate controllers of personal data:
              </p>
              <ul>
                <li>Facebook: <a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a></li>
                <li>Google Analytics: <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage#gtagjs_google_analytics_4_-_cookie_usage">https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage#gtagjs_google_analytics_4_-_cookie_usage</a></li>
                <li>Google login: <a href="https://policies.google.com/technologies/cookies#types-of-cookies">https://policies.google.com/technologies/cookies#types-of-cookies</a></li>
                <li>Hotjar: <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script</a></li>
              </ul>
            </details>
        </section>
      </Container>
    </>
  )
}

export default CookiesPageEn